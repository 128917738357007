#root, html {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.4286;  
}

.stretchedToMargin {
  display: block;
  position:absolute;
  height:auto;
  bottom:0;
  top:0;
  left:0;
  right:0;
  background-color: f3f2f1;
}

.navbar, .navbar-dropdown, a.navbar-item, .navbar-link {
  background-color: #18358A!important;
}

.navbar a {
  color: white!important;
}

.navbar-item.active, .navbar-item:hover, .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link, .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #18358A!important;
  font-weight: 600;
}


body {
  color: #252423;
}

.button.is-primary {
  background-color: #001689!important;
}

.template-square {
  color: #fff;
  background-color: #001689;
}

.template-square {
  overflow:hidden;
  border-radius: 0.15em;
  margin: auto;
  height: 64px;
  width: 64px;
  font-size: 1.4em;
  font-weight: 600;
  line-height: 64px;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  cursor: default;
}

.navbar {
}

.logoNavBarItem {
  font-weight: 600;
}

.footerButtons {
  margin-top: 2em;
  padding-bottom: 2em;
}

.contents {
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 0em;
}

.answeredByRow {
  max-width: 50px;
  display: block;
}

.answeredByRow img {
  float: left;
}

.answeredByRow span {
  float:right;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logoContainer {
  width: 50px;
}

.logo {
  width: 100%!important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .modules-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .manage-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .teams-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .template-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .policy-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .policyDetails-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .setup-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .uniqueKeyDetails-background {
    background-image: url("_images/header-details.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
