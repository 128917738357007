.header-container {
  font-size: 1rem;
  height: 9em;
  margin-bottom: 1.5em;
}

.header-container h1 {
  padding: 0.75em 0 0 0;
  text-align: left;
}

.header-container-subtitle {
  margin-top: 1em;
  font-size: 1em;
}

@media only screen and (max-width: 768px) {
  .header-container h1 {
    font-size: 1.2em;
  }

  .header-container {
    height: 4.5em;
  }
}