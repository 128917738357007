.picker-container {
    position: relative;
}

.picker-container-list {
    width: 100%;
    position: absolute;
    z-index: 10;
}

.picker-container-list-item {
    margin: 0.75em;
}

.picker-container-list-image {
    float: left;
    overflow:hidden;
    border-radius: 0.15em;
    margin: auto;
    height: 2em;
    width: 2em;
    font-size: 1.4em;
    font-weight: 600;
    line-height: 2em;
    text-align: center;
    vertical-align: middle;
    margin-right: 0.5em
}

.picker-container-list-text {
    cursor: pointer;
    font-size: 0.9em;
}

.picker-container-list-text-primary {
    font-weight: 600;
}

.picker-container-list-noItems {
    font-weight: 600;
    margin: 0.75em;
}

