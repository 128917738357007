.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    display: flex;
}

.loader-wrapper .loader {
    height: 80px;
    width: 80px;
}

.loader-wrapper.is-active {
    opacity: 1;
    z-index: 1;
}